import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

function EstimationHero() {
  const data = useStaticQuery(graphql`
    {
      mobileBackground: file(
        relativePath: { eq: "mobile/company-hero-bg.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      desktopBackground: file(relativePath: { eq: "company-hero-bg.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  `)

  return (
    <div className="py-24 relative overflow-hidden">
      <div className="absolute mx-auto w-full max-w-screen-2xl left-0 top-0 right-0 items-center lg:items-start">
        <div className="ml-auto w-1/3 h-auto lg:hidden">
          <GatsbyImage
            alt=""
            image={data.mobileBackground.childImageSharp.gatsbyImageData}
            
          />
        </div>
        <div className="ml-auto w-1/2 h-auto hidden lg:block">
          <GatsbyImage
            alt=""
            image={data.desktopBackground.childImageSharp.gatsbyImageData}
            
          />
        </div>
      </div>
      <div className="container relative">
        <div className="max-w-screen-lg">
          <h1 className="font-medium text-4.5xl md:text-5xl lg:text-6xl leading-tight text-gray-900">
            There’s always a seat at the table for your creative idea
          </h1>
          <span className="py-3 md:pb-10 font-medium text-base md:text-2xl text-medium text-black leading-6 md:leading-8">
            Complete the form below, or get in touch at{" "}
            <a className="text-blue-700" href="mailto:online@newmedia.al">
              online@newmedia.al
            </a>
          </span>
        </div>
      </div>
    </div>
  )
}

export default EstimationHero
