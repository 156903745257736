import React from "react"

import Seo from "../components/seo"
import EstimationHero from "../components/EstimationHero"
import EstimationForm from "../components/EstimationForm"

const EstimationPage = () => (
  <>
    <Seo
      title="Estimation"
      description="There’s always a seat at the table for your creative idea"
    />
    <EstimationHero />
    <EstimationForm />
  </>
)

export default EstimationPage
