import React from "react"
import { Field, getIn } from "formik"

const ErrorMessage = ({ name }) => (
  <Field name={name}>
    {({ form }) => {
      const error = getIn(form.errors, name)
      const touch = getIn(form.touched, name)
      return touch && error ? (
        <span className="text-xs text-red-600 font-medium leading-none">
          {error}
        </span>
      ) : (
        <span className="text-xs font-medium leading-none">&nbsp;</span>
      )
    }}
  </Field>
)

export default ErrorMessage
