import React from "react"
import { Formik, Form, FieldArray, useField } from "formik"
import classnames from "classnames"

import * as Yup from "yup"

import PrimaryButton from "./PrimaryButton"
import Input from "./Form/Input"
import TextArea from "./Form/TextArea"
import UploadFile from "./Form/UploadFile"
import Slider from "./Form/Slider"
import ErrorMessage from "./Form/ErrorMessage"
import * as notification from "./Form/notification"
import { uploadFileToFirebase } from "./Form/utils"
import { sendEstimationEmail } from "../emails/emails"

const budget = {
  min: 1000,
  max: 300000,
}

const projectTypes = [
  "MVP",
  "Web Application",
  "Mobile Application",
  "Design",
  "Hire Our Team",
]

const timeFrames = ["Not Specified", "Less than 6 months", "More than 6 months"]

const estimationSchema = Yup.object().shape({
  name: Yup.string().required().label("Name"),
  email: Yup.string().email().required().label("Email"),
  company: Yup.object({
    name: Yup.string().label("Company Name"),
    website: Yup.string().label("Company Website"),
  }),
  projectType: Yup.array()
    .min(1, "You must select at least 1 item.")
    .label("Project Type"),
  timeFrame: Yup.string().required().label("Time Frame"),
  message: Yup.string().required().label("Message"),
  uploadFile: Yup.array(Yup.mixed()).max(5).label("Documents"),
  budget: Yup.number()
    .required()
    .min(budget.min)
    .max(budget.max)
    .label("Budget"),
  suggestion: Yup.string().label("Suggestion"),
})

const initialValues = {
  name: "",
  email: "",
  company: {
    name: "",
    website: "",
  },
  projectType: [],
  timeFrame: "",
  message: "",
  uploadFile: [],
  suggestion: "",
  budget: 2000,
}

function CheckIcon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z" />
    </svg>
  )
}

function CustomCheckbox({ type, label, className, ...props }) {
  return (
    <div className="inline-block">
      <input
        {...props}
        id={props.id || props.name}
        type={type || "checkbox"}
        className="sr-only h-px custom-checkbox"
      />
      <label
        htmlFor={props.id || props.name}
        className={classnames(
          "block border border-gray-400 cursor-pointer",
          className
        )}
      >
        <div className="flex items-center space-x-2 px-1 py-2 bg-white">
          <CheckIcon className="custom-checkbox-icon w-5 h-5 flex-shrink-0 ml-1 opacity-0 fill-current" />
          <span className="block">{label}</span>
          <div className="w-5 h-5"></div>
        </div>
      </label>
    </div>
  )
}

function FormSection({ title, subtitle, children }) {
  return (
    <div className="mb-4">
      <div>
        <span className="block text-xl md:text-1.5xl font-bold text-gray-900">
          {title}
        </span>
        <span className="block text-gray-700">{subtitle}</span>
      </div>
      <div className="pt-4">{children}</div>
    </div>
  )
}

function CustomRadioFormik({ type, value, ...props }) {
  const [field] = useField(props)
  return (
    <CustomCheckbox
      className="mr-3 mb-3"
      type="radio"
      {...field}
      {...props}
      checked={value === field.value}
      value={value}
    />
  )
}

function uploadFilesToFirebase(files = []) {
  try {
    return Promise.all(files.map(f => uploadFileToFirebase(f, "estimation_")))
  } catch (error) {
    notification.error("Your selected files cannot be upload!")
    return false
  }
}

function EstimationForm() {
  const [isLoading, setIsLoading] = React.useState(false)

  return (
    <div className="py-8 md:py-12 bg-gray-100">
      <div className="container">
        <Formik
          initialValues={initialValues}
          validationSchema={estimationSchema}
          onSubmit={async ({ uploadFile, ...values }, { resetForm }) => {
            try {
              setIsLoading(true)
              const files = await uploadFilesToFirebase(uploadFile)
              const newValues = { ...values, files: files }

              const { data } = await sendEstimationEmail(newValues)
              if (data.message) {
                notification.success(data.message)
              }
              setIsLoading(false)
              resetForm()
            } catch (error) {
              notification.error("Something went wrong. Try again!")
            } finally {
              setIsLoading(false)
            }
          }}
        >
          {formikProps => {
            return (
              <Form className="w-full max-w-4xl">
                <FormSection
                  title="Select project type"
                  subtitle="Select as many as you want."
                >
                  <FieldArray name="projectType">
                    {arrayHelpers => {
                      return (
                        <>
                          <div className="flex flex-wrap">
                            {projectTypes.map((projectType, index) => {
                              return (
                                <CustomCheckbox
                                  className="mr-3 mb-3"
                                  key={projectType}
                                  name={`projectType`}
                                  id={`projectType.${index}`}
                                  value={projectType}
                                  label={projectType}
                                  checked={formikProps.values.projectType.includes(
                                    projectType
                                  )}
                                  onChange={e => {
                                    if (e.target.checked) {
                                      arrayHelpers.push(projectType)
                                    } else {
                                      const idx = formikProps.values.projectType.indexOf(
                                        projectType
                                      )
                                      arrayHelpers.remove(idx)
                                    }
                                  }}
                                />
                              )
                            })}
                          </div>
                          <div className="-mt-3">
                            <ErrorMessage name="projectType" />
                            <div className="h-3"></div>
                          </div>
                        </>
                      )
                    }}
                  </FieldArray>
                </FormSection>
                <FormSection
                  title="Your details"
                  subtitle="Some fields are required"
                >
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4">
                    <Input
                      type="text"
                      name="name"
                      placeholder="Your Name"
                      label="Name"
                    />
                    <Input
                      name="email"
                      type="email"
                      placeholder="Your Email"
                      label="Email"
                    />
                    <Input
                      name="company.name"
                      type="text"
                      placeholder="Company Name"
                      label="Company Name"
                    />
                    <Input
                      name="company.website"
                      type="text"
                      placeholder="Company Website"
                      label="Company Website"
                    />
                  </div>
                </FormSection>
                <FormSection
                  title="Timeframe"
                  subtitle="How soon do you want to release your product?"
                >
                  <div className="flex flex-wrap">
                    {timeFrames.map((timeFrame, index) => {
                      return (
                        <CustomRadioFormik
                          className="mr-3 mb-3"
                          key={timeFrame}
                          name="timeFrame"
                          id={`timeFrame.${index}`}
                          value={timeFrame}
                          label={timeFrame}
                        />
                      )
                    })}
                  </div>
                  <div className="-mt-3">
                    <ErrorMessage name="timeFrame" />
                    <div className="h-3"></div>
                  </div>
                </FormSection>
                <FormSection
                  title="What do you need help with?"
                  subtitle="Share a short summary of your idea."
                >
                  <TextArea
                    name="message"
                    placeholder="Short description about project"
                  />
                  <div className="h-3"></div>
                </FormSection>
                <FormSection
                  title="What your Budget?"
                  subtitle="Knowing your budget will help us to make sure that we can match your expectations."
                >
                  <Slider name="budget" min={budget.min} max={budget.max} />
                  <div className="h-3"></div>
                </FormSection>
                <FormSection
                  title="Attach files"
                  subtitle="Upload here the documents that will help us estimate your project."
                >
                  <div className="h-2"></div>
                  <UploadFile
                    name="uploadFile"
                    acceptedFileTypes={[
                      ".doc",
                      ".docx",
                      ".pdf",
                      "image/png",
                      "image/jpeg",
                    ]}
                    multiple
                    helperText="Supported formats: .doc, .pdf, .jpg, .png"
                  />
                </FormSection>
                <FormSection
                  title="Have some Suggestion?"
                  subtitle="Call time, some question, etc."
                >
                  <TextArea name="suggestion" placeholder="Your suggestion" />
                </FormSection>

                <div>
                  <PrimaryButton disabled={isLoading} type="submit">
                    {isLoading ? "Loading..." : "Submit"}
                  </PrimaryButton>
                </div>
              </Form>
            )
          }}
        </Formik>
      </div>
    </div>
  )
}

export default EstimationForm
