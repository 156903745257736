import React from "react"

import "rheostat/initialize"
import Rheostat from "rheostat"
import "./slider.css"

import { useField } from "formik"
import ErrorMessage from "./ErrorMessage"

function nFormatter(num, digits) {
  var si = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" },
  ]
  var rx = /\.0+$|(\.[0-9]*[1-9])0+$/
  var i
  for (i = si.length - 1; i > 0; i--) {
    if (num >= si[i].value) {
      break
    }
  }
  return (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol
}

/**
 *
 * @param {{ min: number, max: number }} param0
 */
function Slider({ min, max, ...props }) {
  const [, , helpers] = useField(props)
  const [value, setValue] = React.useState(min)

  return (
    <div>
      <div className="h-2"></div>
      <div className="flex items-center">
        <div className="w-12">
          <span className="block font-bold text-gray-700 text-sm leading-none">
            &euro; {nFormatter(value, 0)}
          </span>
        </div>
        <div className="flex-1 px-4">
          <Rheostat
            min={min}
            max={max}
            onChange={state => {
              setValue(state.values[0])
              helpers.setValue(state.values[0])
            }}
            onValuesUpdated={state => {
              setValue(state.values[0])
            }}
            values={[value]}
          />
        </div>
        <div className="w-12">
          <span className="block font-bold text-gray-700 text-sm leading-none text-right">
            &euro; {nFormatter(max, 0)}
          </span>
        </div>
      </div>
      <div className="h-2"></div>
      <ErrorMessage name={props.name} />
    </div>
  )
}

export default Slider
